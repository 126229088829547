import _ from 'lodash'
import {
  SAVE_DRAFT_SUCCESS,
  FETCH_POST_SUCCESS,
  FETCH_DRAFT_SUCCESS,
  DELETE_DRAFT_SUCCESS,
  SET_POST_LIST,
  SET_USER_POST_LIST,
  SET_PROFILE_POST_LIST,
  SET_USER_VIEW_LIST,
  SET_USER_MARK_LIST,
  SET_USER_DRAFT_LIST,
  CREATE_POST_MARK,
  DELETE_POST_MARK,
  CHECK_POST_FLAGS,
  GET_POST_CHARGE_LIST,
  SET_POST_SUGGESTS,
  SET_COMPARE_POST_LIST,
  SET_COMPARE_LOADING
} from '~/redux/actions/post'

const initialState = {
  post: null,
  draft: null,
  drafts: [],
  postList: null,
  suggests: null,
  userPostList: null,
  userViewList: null,
  userMarkList: null,
  userDraftList: null,
  postCharges: null,
  postChargesCount: null,
  postChargesEle: null,
  postCharged: null,
  flags: {
    marked: []
  }, 
  comparePostList: null,
  compareLoading: false
}

const deleteDraft = (list, id) => {
  const { drafts = [], pager } = list || {}

  return {
    drafts: _.filter(drafts, x => x.id !== id),
    pager: pager
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POST_SUCCESS:
      return {
        ...state,
        post: action.post
      }
    case FETCH_DRAFT_SUCCESS:
      return {
        ...state,
        draft: action.draft
      }
    case DELETE_DRAFT_SUCCESS:
      return {
        ...state,
        userDraftList: deleteDraft(state.userDraftList, action.id)
      }
    case SET_POST_LIST:
      return {
        ...state,
        postList: action.list
      }
    case SET_USER_POST_LIST:
      return {
        ...state,
        userPostList: action.list
      }
    case SET_PROFILE_POST_LIST:
      return {
        ...state,
        profilePostList: action.list
      }
    case SET_USER_VIEW_LIST:
      return {
        ...state,
        userViewList: action.list
      }
    case SET_USER_MARK_LIST:
      return {
        ...state,
        userMarkList: action.list
      }
    case SET_USER_DRAFT_LIST:
      return {
        ...state,
        userDraftList: action.list
      }
    case SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        draft: action.draft
      }
    case CREATE_POST_MARK:
      return {
        ...state,
        flags: {
          ...state.flags,
          marked: [...state.flags.marked, action.id]
        }
      }
    case DELETE_POST_MARK:
      return {
        ...state,
        flags: {
          ...state.flags,
          marked: (function () {
            const marked = _.clone(state.flags.marked)
            _.pull(marked, action.id)
            return marked
          })()
        }
      }
    case CHECK_POST_FLAGS:
      return {
        ...state,
        flags: {
          ...state.flags,
          ...action.flags
        }
      }
    case GET_POST_CHARGE_LIST:
      return {
        ...state,
        postCharges: action.charges,
        postChargesCount: action.charges_count,
        postChargesEle: action.charges_ele,
        postCharged: action.charged
      }
    case SET_POST_SUGGESTS:
      return {
        ...state,
        suggests: action.suggests
      }
    case SET_COMPARE_POST_LIST:
      return {
        ...state,
        comparePostList: action.list
      }
    case SET_COMPARE_LOADING:
      return {
        ...state,
        compareLoading: action.loading
      }
    default:
      return state
  }
}
