import { useEffect, useState, useMemo } from "react";
import { useRouter } from "next/router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { AutoComplete, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { showAppMessage, getKeywords } from "../../redux/actions/app";

import lscache from "lscache";
import _ from "lodash";

const AppSearch = () => {
  const router = useRouter();
  const [keywords, setKeywords] = useState([]);
  const [options, setOptions] = useState();
  const [searchText, setSearchText] = useState("");

  const handleSearch = (key = searchText) => {
    const keyword = encodeURIComponent(_.trim(key));
    keyword && router.push("/search?keyword=" + keyword);
  };

  const fetchKeywords = () => {
    getKeywords().then((res) => {
      setKeywords(res);
    });
  };

  const renderItem = () =>
    _.sampleSize(keywords, 4).map((keyword) => ({
      value: keyword.name,
      label: keyword.name,
      key: keyword.hashid,
    }));

  const generateOptions = () => {
    const keywordOptions =
      keywords.length > 0
        ? [
            {
              label: (
                <div className="option-title">
                  <div>大家在搜</div>
                </div>
              ),
              options: renderItem(),
            },
          ]
        : [];
    const localKeywords = lscache.get("keywords")
      ? _.reverse(lscache.get("keywords"))
      : [];
    const localKeywordOptions =
      localKeywords.length > 0
        ? [
            {
              label: (
                <div className="flex space-between option-title align-items-center">
                  <div>搜索记录</div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      lscache.remove("keywords");
                      generateOptions();
                    }}
                  >
                    <DeleteOutlined />
                    清空
                  </div>
                </div>
              ),
              options: localKeywords.map((localKeyword) => ({
                value: localKeyword,
                label: localKeyword,
                key: localKeyword + "-local",
              })),
            },
          ]
        : [];

    setOptions(keywordOptions.concat(localKeywordOptions));
  };

  useEffect(() => {
    generateOptions();
  }, [keywords]);

  return (
    <>
      <AutoComplete
        dropdownMatchSelectWidth={252}
        style={{ width: 170, verticalAlign: "middle" }}
        listHeight="100%"
        options={options}
        onChange={(value) => setSearchText(value)}
        onFocus={() => fetchKeywords()}
        onSelect={handleSearch}
        onDropdownVisibleChange={() => {
          generateOptions();
        }}
        className="app-search"
      >
        <Input.Search
          size="middle"
          placeholder="请输入关键字"
          enterButton
          onSearch={() => handleSearch()}
        />
      </AutoComplete>
      <style jsx global>
        {`
          .option-title {
            border-bottom: 1px solid #dbdbdb;
            padding: 5px;
          }
          @media screen and (max-width: 960px) {
            .app-search {
              width: 100% !important;
              margin-bottom: 10px;
              border-radius: 25px;
            }
            .app-search .ant-btn-primary {
              background-color: #dbdbdb;
              border: 1px solid #dbdbdb;
            }
          }
        `}
      </style>
    </>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAppMessage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppSearch);
